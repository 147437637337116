
import { Component, Vue } from 'vue-property-decorator';
import {LMap, LTileLayer} from 'vue2-leaflet';

@Component({
    components: {
        LMap,
    LTileLayer,
    },
})

export default class About extends Vue {
  
}
